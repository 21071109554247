<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Permissions" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div style="display: flex; align-items: center">
          <div>
            <md-button :to="{ name: 'Role Management' }" class="md-primary">
              Roles
            </md-button>
          </div>
          <md-button class="md-primary" :to="{ name: 'Create Permission' }">
            Add New Permission
          </md-button>
        </div>
      </div>
      <md-table
        v-model="permissions.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4"> </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S/N">{{ index + 1 }}</md-table-cell>
          <md-table-cell md-label="Name">{{ item.display_name }}</md-table-cell>
          <md-table-cell md-label="Slug">{{ item.name }}</md-table-cell>
          <md-table-cell md-label="Guard Name">{{
            item.guard_name
          }}</md-table-cell>
          <md-table-cell md-label="Description">{{
            item.description
          }}</md-table-cell>
          <md-table-cell md-label="Actions">
            <!-- :to="{ name: 'Edit Permission', params: { id: item.id } }" -->
            <md-button
              :disabled="updating"
              class="md-default md-icon-button md-raised btn-icon"
              ><i class="material-icons">edit</i></md-button
            >
            <md-button
              :disabled="updating"
              @click="remove(item.id)"
              class="bggreen md-icon-button md-raised btn-icon"
              ><i class="material-icons">delete</i></md-button
            >
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="permissions.mdPage"
        :records="permissions.mdCount"
        :per-page="permissions.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>User Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="user">
        <div class="item">
          <strong>First name</strong>
          <span> {{ user.first_name }}</span>
        </div>
        <div class="item">
          <strong>Last name</strong>
          <span> {{ user.last_name }}</span>
        </div>
        <div class="item">
          <strong>Email Address</strong>
          <span v-html="user.email"> </span>
        </div>
        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${user.phone_ext + user.phone}`">
            {{ user.phone_ext + user.phone }}</a
          >
        </div>

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ user.created_at | formatDate }}</span>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import axios from "axios";
const { mapActions, mapGetters } = createNamespacedHelpers("user");
export default {
  name: "User",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      show: false,
      time: null,
      user: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getPermissions", "loginAsUser", "deletePermissions"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getPermissions(data);
    },
    async submitSearch() {
      await this.getPermissions({ keyword: this.search });
    },

    setUser(user) {
      this.show = true;
      this.user = user;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async loginAs(id, name) {
      const c = confirm(`Are you sure you want to login as ${name}?`);

      if (c) {
        const { token, redirect_url } = await this.loginAsUser(id);
        window.open(redirect_url, "_blank");
      }
    },
    async remove(id) {
      let conf = confirm("Are you sure?");
      if (conf) {
        this.deleting = true;
        await this.deletePermissions(id);
        this.toast("success", "Permission Deleted");
        this.deleting = false;
      }
    },
    async deleteUser(id) {
      const c = confirm(`Are you sure? This can't be reversed`);
    },
    async validate() {
      await axios.post("http://127.0.0.1:8000/api/admin/auth/validate", {
        code: "2652|ldUWo3fGaIvWobEcBBK2qItY7qXR5NNJb9bhybVw",
      });
    },
  },
  created() {},
  mounted() {
    this.getPermissions();
  },
  computed: {
    ...mapGetters(["permissions", "loading"]),
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}
</style>
